<template>
  <bs-popup id="master-customer-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
        <template v-slot:title>Fee Profile</template>
        <template v-slot:content>
            <div class="mb-3">
				<label for="nama" class="form-label">Fee Profile</label>
				<bs-plain-text v-model="model.feeProfileNama" />
			</div>
            <div class="mb-3">
                <label for="nama" class="form-label">Fee Name</label>
                <bs-plain-text v-model="feeList[model.feeId-1].name" v-if="model.feeId > 0"/>
            </div>
            <div class="mb-3">
                <label for="code" class="form-label">Remark</label>
                <bs-plain-text v-model="model.remarks"/>
            </div>
            <div class="mb-3">
                <label for="nama" class="form-label">Quantity</label>
                <bs-plain-text type="number" v-model="model.quantity"/>
            </div>
            <div class="mb-3">
                <label for="nama" class="form-label">Valid Start</label>
                <bs-date-picker v-model="model.validStart" disabled/>
            </div>
            <div class="mb-3">
                <label for="nama" class="form-label">Valid End</label>
                <bs-date-picker v-model="model.validEnd" disabled/>
            </div>
            <div class="mb-3">
                <label for="nama" class="form-label">Status: <span class="fw-bold">{{ statusList[model.status] }}</span></label>
            </div>
        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
  </bs-popup>
</template>

<script>
import FeeService from "@/services/fee-service.js";
import {OPSI_START_DAY} from "@/plugins/constant";

export default {
  data: function() {
      return {
            id: null,
            model: FeeService.default(),
            errorMessages: null,
            feeList: [
                {
                    id: 1,
                    name: "Government Fee",
                },
                {
                    id: 2,
                    name: "Throughput Fee",
                },
                {
                    id: 3,
                    name: "Flowage Fee",
                },
            ],
            statusList: [
                "Not OK",
                "OK",
                "OK-Revised"
            ],
            inputFees: [],
            feeProfileList: [],
            month: 0,
      }
  },
  mounted() {
      let id = this.$route.params.id;
      this.id = id;
      this.loadData();
  },
  methods: {
      async loadData() {
            let response = await FeeService.getObject(this.id);
            console.log(response);
            this.model =  {
                feeProfileNama: response.data.feeProfileNama,
                feeId: response.data.feeId,
                remarks: response.data.remarks,
                quantity: response.data.quantity,
                validStart: new Date(response.data.validStart),
                validEnd: new Date(response.data.validEnd),
                status: response.data.status,
            };
            if (response.status) 
            {
                this.errorMessages = null;
            }
            else 
            {
                this.errorMessages = response.errorMessages;
            }
      },
      onPopupClosed() {
          this.$router.push('/fee');
      },
      close() {
          this.$refs.form.closePopup();
      },
      // editData() 
      // {
      //     this.$refs.form.closePopup();
      //     this.$router.push(`/fee-profile/edit/${this.id}`);
      // }
      formatStartDay(day){
          if(day == 0) day = 7;
          return OPSI_START_DAY.filter((item)=>item.value === day)[0].label;
      },
      formatDummyFee(id){
          var feeList = [
      {
        id: 1,
        name: "Government Fee",
      },
      {
        id: 2,
        name: "Throughput Fee",
      },
      {
        id: 3,
        name: "Flowage Fee",
      },
    ];
          return feeList[id-1]?.name;
      }
  }
}
</script>